import React from 'react';
import myImg from "../Resources/Images/About.jpg";
import { Icon } from '@iconify/react';
import styles from "./About.module.css";

export default function About() {
  return (
    <section id="About" className={`${styles.bg1}`}>
    <div className={`${styles.section} container py-5`}>
      <div className="row w-100 m-auto">
        <strong
          className={`text-center mt-5 text-white fs-1 bordHover border-bottom border-bottom-2 rounded-4 ${styles.bg1} mb-3`}
        >
          About Me
        </strong>

        {/* Left Column data */}
        <div className="col-md-6 mt-4 d-flex justify-content-center">
          {/* Welcome paragraph */}
          <div className="caption text-white">
            <h3 className={`${styles.helloWord}`}>Hey,</h3>
            <h1>
              I'm
              <span className={`${styles.myName} fw-bolder`}>
                {" "}
                Mohammed Nabil
              </span>
            </h1>
            <p className={`${styles.paragraph} h4 `}>
              Front End web Developer
            </p>
            <p className="justify">
              Experienced in working on Responsive web Apps using React, JS,
              TS, Redux, Sass, and Rest API's. Seeking a new challenge to
              improve technical and business skills.
            </p>
            {/* My contact Info */}
            <div className="my-contact ms-4 mb-3">
              {/* My Email */}
              <div className="d-flex align-items-center mb-3">
                <i
                  className={`fa-solid fa-envelope fs-5 me-2 ${styles.envelope}`}
                ></i>
                <a
                  className="text-center fs-6 mailto"
                  href="mailto:mohammednabil1910@gmail.com"
                >
                  mohammednabil1910@gmail.com
                </a>
              </div>
              {/* Adrress */}
              <div className="d-flex align-items-center mb-3">
                <i
                  className={`fa-solid fa-location-dot fs-5 me-2 ${styles.location}`}
                ></i>
                <span className="text-center fs-6">
                  El-Shorouk City 2, Cairo{" "}
                </span>
              </div>
              {/* Phone Number */}
              <div className="d-flex align-items-center">
                <i
                  className={`fa-solid fa-phone fs-5 me-2 ${styles.Phone}`}
                ></i>
                <span className="text-center fs-6">01090594627</span>
              </div>
            </div>
            {/* --------------------------------------------Social Media Icons------------------------- */}
            <div className="fs-3">
              <a
                href="https://www.facebook.com/MoNbail19/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  className={`text-white fa-brands ${styles.facebook} fa-facebook mx-3 text-dark`}
                ></i>
              </a>
              <a
                href="https://github.com/MuhammeedNabil?tab=repositories"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  className={`text-white fa-brands ${styles.github} fa-github text-dark`}
                ></i>
              </a>
              <a
                href="https://www.instagram.com/mohammed_nabil_elpop/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  className={`text-white fa-brands ${styles.instagram} fa-instagram mx-3 text-dark`}
                ></i>
              </a>
              <a
                href="https://www.linkedin.com/in/m-nabilbkr/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i
                  className={`text-white fa-brands ${styles.linkedin} fa-linkedin text-dark`}
                ></i>
              </a>
              <a
                href="https://twitter.com/MohammeedNabil"
                target={"_blank"}
                rel="noreferrer"
              >
                <i>
                <Icon icon="fa6-brands:x-twitter" className={`text-white mb-2 ${styles.twitter} text-dark mx-3`} />
                </i>
              </a>
            </div>
          </div>
          {/* close of Welcome paragraph */}
        </div>
        {/* Close Left Column data */}

        {/* ------------------------------Right Column data--------My Image in home page------------------------- */}
        <div className="col-md-6 mt-4 d-flex justify-content-center">
          <img
            className="w-75 rounded-5 border border-5 border-muted bordHover"
            src={myImg}
            alt=""
          />
        </div>
      </div>

      {/* the close of container div */}
    </div>
  </section>
  )
}
